.menu-superior{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    .logo_editorial{
        width: 55%;
        margin-left: 5%;

        display: flex;
        flex-direction: row;
        align-items: center;
        
        img{
            width: 90px;
            height: 90px;
        }

        .text_edi{
            margin-left: 2%;
            font-size: 34px;
            font-family: 'Roboto', sans-serif;
            color: #ffffff;
            font-weight: 500;
        }
    }

    .name_book{
        width: 40%;
        
        display: flex;
        flex-direction: column;

        .text-libro{
            font-size: 30px;
            font-family: 'Roboto', sans-serif;
            color: #efff0c;
            font-weight: 500;
        }

        Button{
            margin-top: 10px;
            width: 170px;
        }

    }

}

@media screen and (min-width: 320px) and (max-width: 400px){ 
    .menu-superior{
        flex-direction: column;

        .logo_editorial{
            width: 100%;
            margin-top: 2%;
            margin-left: 0;
            justify-content: center;
            
            img{
                width: 40px;
                height: 40px;
            }

            .text_edi{
                font-size: 18px;
            }
        }

        .name_book{
            width: 100%;
            margin-top: 2%;

            align-items: center;
            .text-libro{
                font-size: 16px;
            }

            Button{
                margin-top: 2%;
                width: 100px;

                span{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (min-width: 401px) and (max-width: 450px){ 
    .menu-superior{
        flex-direction: column;

        .logo_editorial{
            width: 100%;
            margin-top: 2%;
            margin-left: 0;
            justify-content: center;
            
            img{
                width: 50px;
                height: 50px;
            }

            .text_edi{
                font-size: 20px;
            }
        }

        .name_book{
            width: 100%;
            // margin-top: 1%;

            align-items: center;
            .text-libro{
                font-size: 18px;
            }

            Button{
                margin-top: 1%;
                width: 120px;

                span{
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 451px) and (max-width: 500px){ 
    .menu-superior{
        flex-direction: column;

        .logo_editorial{
            width: 100%;
            margin-top: 1%;
            margin-left: 0;
            justify-content: center;
            
            img{
                width: 50px;
                height: 50px;
            }

            .text_edi{
                font-size: 22px;
            }
        }

        .name_book{
            width: 100%;
            // margin-top: 1%;

            align-items: center;
            .text-libro{
                font-size: 20px;
            }

            Button{
                margin-top: 1%;
                width: 120px;

                span{
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 501px) and (max-width: 550px){ 
    .menu-superior{
        flex-direction: column;

        .logo_editorial{
            width: 100%;
            margin-top: 1%;
            margin-left: 0;
            justify-content: center;
            
            img{
                width: 50px;
                height: 50px;
            }

            .text_edi{
                font-size: 24px;
            }
        }

        .name_book{
            width: 100%;

            align-items: center;
            .text-libro{
                font-size: 22px;
            }

            Button{
                margin-top: 1%;
                width: 120px;

                span{
                    font-size: 16px;
                }
            }
        }
    }
}


@media screen and (min-width: 551px) and (max-width: 650px){ 
    .menu-superior{   
        .logo_editorial{           
            img{
                width: 80px;
                height: 80px;
            }    
            .text_edi{
                font-size: 24px;
            }
        }
    
        .name_book{
            .text-libro{
                font-size: 20px;
            }
    
            Button{
                margin-top: 10px;
                width: 170px;
            }    
        }    
    }
}


@media screen and (min-width: 651px) and (max-width: 720px){ 
    .menu-superior{   
        .logo_editorial{           
            img{
                width: 90px;
                height: 90px;
            }    
            .text_edi{
                font-size: 26px;
            }
        }
    
        .name_book{
            .text-libro{
                font-size: 22px;
            }
    
            Button{
                margin-top: 12px;
                width: 170px;
            }    
        }    
    }
}


@media screen and (min-width: 721px) and (max-width: 760px){ 
    .menu-superior{   
        .logo_editorial{           
            img{
                width: 90px;
                height: 90px;
            }    
            .text_edi{
                font-size: 28px;
            }
        }
    
        .name_book{
            .text-libro{
                font-size: 24px;
            }
    
            Button{
                margin-top: 14px;
                width: 170px;
            }    
        }    
    }
}


@media screen and (min-width: 761px) and (max-width: 805px){ 
    .menu-superior{   
        .logo_editorial{           
            img{
                width: 90px;
                height: 90px;
            }    
            .text_edi{
                font-size: 30px;
            }
        }
    
        .name_book{
            .text-libro{
                font-size: 26px;
            }
    
            Button{
                margin-top: 16px;
                width: 170px;
            }    
        }    
    }
}


@media screen and (min-width: 806px) and (max-width: 850px){ 
    .menu-superior{   
        .logo_editorial{           
            img{
                width: 90px;
                height: 90px;
            }    
            .text_edi{
                font-size: 32px;
            }
        }
    
        .name_book{
            .text-libro{
                font-size: 28px;
            }
    
            Button{
                margin-top: 18px;
                width: 170px;
            }    
        }    
    }
}