.pie-pagina {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
        color: white;
        font-family: 'Roboto';
        font-size: 16px;
        text-align: center;
        margin: 0;
    }
}