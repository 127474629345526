._oGACf{
    margin-top: 3%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div{

        ._search_47p46_1{
            max-width: 300px;
            margin-left: 10%;
        }
    
        ._wrapper_1sefb_1{
            width: 80%;
            margin-left: 10%;
        }
    
        ._wrapper_v7kbd_1{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
    
            p{
                width: 150px;
                height: 40px;
                margin-right: 12px;
                margin-left: 12px;
                padding-top: 8px;
            }
        }

    }

}

