*{
    margin: 0;
    padding: 0;
}
body{
    background-color: #3ab0ff;
}
.Principal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contentPrincipal{
    margin-top: 4%;
    width: 70%;
    display: flex;
    flex-direction: row;
}
.welcome{
    width: 56%;
}
.welcome img{
    width: 100%;
    height: 100%;
}
.contenidoLogin{
    background-color: #ffffff;
    width: 44%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo{
    height: 20%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo img{
    height: 100%;
}
form{
    margin-top: 3%;
    width: 70%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* 
.nombreLogin{
    margin-top: ; 
} */

.nombreLogin p{
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    text-align: center;
}
.datos_ingresar{
    margin-top: 10%;
    width: 100%;
}
.datos_ingresar p{
    font-family: 'Roboto', sans-serif;
    margin-bottom: 2%;
}
.datos_ingresar input{
    width: 90%;
    border: none;
    background: #EDEDED;
    height: 40px;
    border-radius: 20px;
    padding-left: 5%;
    padding-right: 5%;
}
.btn{
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn input{
    border: none;
    width: 50%;
    height: 40px;
    background: -webkit-linear-gradient(right, #FF5788, #F6931E);
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
}
.btn input:hover{
    cursor: pointer;
    
}
.error{
    display: flex;
    margin-top: 1%;
    color: #00f;
}
.error p{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #00f;
}

/*///////////////////////////////////// MEDIA QUERY ///////////////////////////////////////////*/

@media screen and (min-width: 800px) and (max-width: 1024px){
    .Principal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .contentPrincipal{
        margin-top: 6%;
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .welcome{
        display: none;
    }
    .contenidoLogin{
        background-color: #ffffff;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo{
        margin-top: 5%;
        height: 20%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo img{
        height: 100%;
    }
    form{
        width: 70%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nombreLogin{
        margin-top: 10%;
    }
    .nombreLogin p{
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        text-align: center;
    }
    .btn{
        margin-bottom: 6%;
    }
    .btn:hover{
        cursor: pointer;
        opacity: 0.5;
    }
    .error{
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .error p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #00f;
    }
}

@media screen and (min-width: 600px) and (max-width: 799px){
    .Principal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .contentPrincipal{
        margin-top: 20%;
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .welcome{
        display: none;
    }
    .contenidoLogin{
        background-color: #ffffff;
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo{
        margin-top: 5%;
        height: 20%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo img{
        height: 100%;
    }
    form{
        width: 70%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nombreLogin{
        margin-top: 10%;
    }
    .nombreLogin p{
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        text-align: center;
    }
    .btn{
        margin-bottom: 6%;
    }
    .error{
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .error p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #00f;
    }
}

@media screen and (min-width: 500px) and (max-width: 599px){
    .Principal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .contentPrincipal{
        margin-top: 6%;
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .welcome{
        display: none;
    }
    .contenidoLogin{
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo{
        margin-top: 5%;
        height: 20%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo img{
        height: 100%;
    }
    form{
        width: 70%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nombreLogin{
        margin-top: 10%;
    }
    .nombreLogin p{
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        text-align: center;
    }
    .btn{
        margin-bottom: 6%;
    }
    .error{
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .error p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #00f;
    }
}
@media screen and (min-width: 400px) and (max-width: 499px){
    .Principal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .contentPrincipal{
        margin-top: 6%;
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .welcome{
        display: none;
    }
    .contenidoLogin{
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo{
        margin-top: 5%;
        height: 20%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo img{
        height: 100%;
    }
    form{
        width: 80%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nombreLogin{
        margin-top: 10%;
    }
    .nombreLogin p{
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        text-align: center;
    }
    .datos_ingresar p{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin-bottom: 2%;
    }
    .datos_ingresar input{
        width: 90%;
    }
    .btn{
        margin-bottom: 8%;
    }
    .error{
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .error p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #00f;
        font-size: 14px;
    }
}

@media screen and (min-width: 300px) and (max-width: 399px){
    .Principal{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .contentPrincipal{
        margin-top: 15%;
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: row;
        
    }
    .welcome{
        display: none;
    }
    .contenidoLogin{
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .logo{
        margin-top: 5%;
        width: 60%;
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo img{
        height: 100%;
    }
    form{
        width: 80%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nombreLogin{
        margin-top: 10%;
    }
    .nombreLogin p{
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        text-align: center;
    }
    .datos_ingresar p{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin-bottom: 2%;
    }
    .datos_ingresar input{
        width: 90%;
        height: 30px;
    }
    .btn{
        margin-bottom: 8%;
        height: 30px;
    }
    .btn input{
        font-size: 14px;
    }
    .error{
        margin-top: 3%;
        margin-bottom: 5%;
    }
    .error p{
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #00f;
        font-size: 14px;
    }
}